import {
  Button,
  RadioGroup,
  useCallbackRef,
} from "@faxi/web-component-library";
import { FormField, FormRef, useFormRefValues } from "@faxi/web-form";
import {
  APP_URI,
  DEPENDENT_FIELDS,
  FormSteps,
  StorageKeys,
  YesNo,
  getYesNoOptions,
} from "common";
import {
  FormActions,
  FormStep,
  NumberInputField,
  RequiredLabel,
} from "components";
import parse from "html-react-parser";
import { useAnalytics } from "providers";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { storageService } from "services";
import { clearFormFields, parseLokaliseNewLine, validations } from "utils";

import { OptionModalLabel } from "../Step4/components";
import { StyledStepPage } from "./Step2-SingleWithChildren.styled";

const onHaveChildrenUnder18Change = (haveChildrenUnder18: string) => {
  if (haveChildrenUnder18 === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.haveChildrenUnder18);
};

const onPayForChildcareChange = (payForChildcare: string) => {
  if (payForChildcare === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.payForChildcare);
};

const onAnyChildBenefitsChange = (anyChildBenefits: string) => {
  if (anyChildBenefits === YesNo.NO)
    clearFormFields(DEPENDENT_FIELDS.anyChildBenefits);
};

const Step2SingleWithChildren: FC = () => {
  const [form, formRef] = useCallbackRef<FormRef>();
  const { t } = useTranslation();

  const history = useHistory();
  const analytics = useAnalytics();

  const haveChildrenUnder18 = useFormRefValues(form, "haveChildrenUnder18");
  const payForChildcare = useFormRefValues(form, "payForChildcare");
  const childcareCosts = useFormRefValues(form, "childcareCosts");
  const anyChildBenefits = useFormRefValues(form, "anyChildBenefits");
  const childcareAllowance = useFormRefValues(form, "childcareAllowance");

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: history.location.pathname,
    });
  }, [history.location?.pathname, analytics]);

  const formValues = useMemo(
    () => ({
      ...haveChildrenUnder18,
      ...payForChildcare,
      ...childcareCosts,
      ...anyChildBenefits,
      ...childcareAllowance,
    }),
    [
      haveChildrenUnder18,
      childcareCosts,
      payForChildcare,
      childcareAllowance,
      anyChildBenefits,
    ],
  );

  const handleSubmit = useCallback(async () => {
    storageService.setItem(
      StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
      FormSteps.STEP_2_1,
    );

    handleTrackEvent();

    history.push(APP_URI.STEP_3);
  }, [handleTrackEvent, history]);

  const doesHaveChildrenUnder18 = formValues.haveChildrenUnder18 === YesNo.YES;
  const doesPayForChildCare = formValues.payForChildcare === YesNo.YES;
  const doesHaveChildBenefits = formValues?.anyChildBenefits === YesNo.YES;

  return (
    <StyledStepPage>
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="haveChildrenUnder18"
          options={getYesNoOptions(t)}
          component={RadioGroup}
          orientation="column"
          onChange={onHaveChildrenUnder18Change}
          validate={validations(t).selectAnyValue}
          label={
            <OptionModalLabel
              label={
                <RequiredLabel>
                  {t("materialstatus_question_under18")}
                </RequiredLabel>
              }
              modalProps={{
                title: "",
                description: t("materialstatus_question_under18_modal"),
              }}
              labelClassName="field-modal-label"
              labelPosition="right"
            />
          }
        />
        {doesHaveChildrenUnder18 && (
          <>
            <FormField
              name="numOfChildren"
              label={t("materialstatus_question_howmanychildren")}
              validate={validations(t).betweenOneAndNine}
              component={NumberInputField}
              prefix=" "
              placeholder=" "
            />
            <FormField
              name="payForChildcare"
              options={getYesNoOptions(t)}
              component={RadioGroup}
              validate={validations(t).selectAnyValue}
              orientation="column"
              onChange={onPayForChildcareChange}
              label={
                <OptionModalLabel
                  label={
                    <RequiredLabel>
                      {t("materialstatus_question_childcare")}
                    </RequiredLabel>
                  }
                  modalProps={{
                    title: "",
                    description: parseLokaliseNewLine(
                      t("materialstatus_question_benefits_howmuch_modal"),
                    ),
                  }}
                  labelClassName="field-modal-label"
                  labelPosition="right"
                />
              }
            />
            {doesPayForChildCare && (
              <FormField
                name="childcareCosts"
                label={
                  <OptionModalLabel
                    label={
                      <RequiredLabel>
                        {t("materialstatus_question_childcare_howmuch")}
                      </RequiredLabel>
                    }
                    modalProps={{
                      title: "",
                      description: t(
                        "materialstatus_singlewchildren_question_howmuch",
                      ),
                    }}
                    labelClassName="field-modal-label"
                    labelPosition="right"
                  />
                }
                validate={validations(t).positiveNumber}
                component={NumberInputField}
              />
            )}
            <FormField
              name="anyChildBenefits"
              options={getYesNoOptions(t)}
              component={RadioGroup}
              orientation="column"
              onChange={onAnyChildBenefitsChange}
              validate={validations(t).selectAnyValue}
              label={
                <OptionModalLabel
                  label={
                    <RequiredLabel>
                      {t("materialstatus_question_benefits")}
                    </RequiredLabel>
                  }
                  modalProps={{
                    title: "",
                    description: parseLokaliseNewLine(
                      t("materialstatus_question_benefits_modal"),
                    ),
                  }}
                  labelClassName="field-modal-label"
                  labelPosition="right"
                />
              }
            />
            {doesHaveChildBenefits && (
              <FormField
                name="childcareAllowance"
                label={
                  <OptionModalLabel
                    label={
                      <RequiredLabel>
                        {t("materialstatus_question_childcare_howmuch")}
                      </RequiredLabel>
                    }
                    modalProps={{
                      title: "",
                      description: parse(
                        t("materialstatus_question_benefits_howmuch_modal"),
                      ),
                    }}
                    labelClassName="field-modal-label"
                    labelPosition="right"
                  />
                }
                className="fc-step2__how-much"
                validate={validations(t).positiveNumber}
                component={NumberInputField}
              />
            )}
          </>
        )}
        <FormActions hasBackButton>
          <Button type="submit" disabled={!form?.syncFormValid}>
            {t("next")}
          </Button>
        </FormActions>
      </FormStep>
    </StyledStepPage>
  );
};

export default Step2SingleWithChildren;
