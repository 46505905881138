import { validationRegexes } from "@faxi/web-form";
import XRegExp from "xregexp";

const user = {
  first_name: XRegExp("^[\\p{L}]{2,}$"),
  last_name: XRegExp("^[\\p{L}]{3,}[\\p{Zs}\\p{L}]*$"),
  email: validationRegexes.workEmail,
  phone: XRegExp("^0\\d{9}$"),
  confirm_contact: (errorMessage: string) => (value: boolean) =>
    value ? "" : errorMessage,
};

export default {
  user,
};
