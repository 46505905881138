import { theme } from "@faxi/web-component-library";
import { flex, fontSize, size } from "@faxi/web-css-utilities";
import styled from "styled-components";

const StyledLoanApproved = styled.div`
  ${flex("column", "flex-start", "center")};

  gap: ${theme.sizes.SIZE_24};
  padding-top: ${theme.sizes.SIZE_160};

  max-width: 436px;
  margin: auto;
  background-color: var(--WHITE);

  .fc-loan-approved {
    &__image-wrapper {
      ${size("100%")};

      display: flex;
      position: relative;

      overflow: hidden;
      max-width: 436px;

      > img {
        ${size("100%")};
        animation: overlayFadeIn 0.6s forwards;
      }
    }

    &__image-overlay {
      ${size("100%")};
      padding: 0 ${theme.sizes.SIZE_16};

      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      animation: backgroundFadeIn 0.6s forwards;

      ${flex("column", "center", "center")};
      gap: ${theme.sizes.SIZE_8};

      p {
        text-align: center;
        color: white;
      }

      .fc-loan-offer-text {
        padding: 0 ${theme.sizes.SIZE_40};
      }

      &__great-news {
        ${flex("row", "center", "center")};
        ${fontSize("19px")};

        gap: ${theme.sizes.SIZE_8};
        color: white;
        margin-bottom: ${theme.sizes.SIZE_8};
      }

      &__pre-approved {
        font-weight: bold;
        margin-bottom: ${theme.sizes.SIZE_8};
      }

      &__price {
        min-width: ${theme.sizes.SIZE_176};
        position: relative;

        p {
          width: 100%;
          font-weight: 600;
          white-space: nowrap;
          overflow: visible;
          padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
          position: relative;
          color: black;
          z-index: 2;
          text-align: center;
        }

        .image-overlay__bg-animation {
          top: 0;
          z-index: 1;
          animation: bgWhiteAnimation 0.6s forwards;
          position: absolute;
          min-width: ${theme.sizes.SIZE_176};
          height: 36px;
        }
      }
    }

    &__subheader {
      width: 100%;
      padding: 0 ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_8};

      &__full {
        color: var(--PRIMARY_EB);
        font-weight: 600;
      }

      > hr {
        max-width: 436px;
      }

      > h2 {
        margin: 0 auto;
        text-align: center;
        ${fontSize("16px")}
      }
    }

    &__reference {
      ${flex("column", "flex-start", "center")};
      gap: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_24};
      background-color: var(--GRAY-EF);

      &__text {
        ${fontSize("14px")};
      }

      &__number {
        font-weight: 700;
      }

      &__email-text {
        font-weight: 600;
        ${fontSize(theme.fontSizes.FONT_16)};
        text-align: center;
        padding: 0 ${theme.sizes.SIZE_10};
      }
    }

    &__mail-me-button {
      margin-bottom: ${theme.sizes.SIZE_24};
      padding: 0 ${theme.sizes.SIZE_16};
      max-width: 436px;
      width: 100%;

      button {
        width: 100%;
        border-radius: 999px;
      }
    }

    &__next-step {
      padding: 0 ${theme.sizes.SIZE_16};
      color: var(--TEXT_PRIMARY);

      ul {
        padding: 0 0 0 ${theme.sizes.SIZE_24};

        li {
          ${fontSize("14px")};

          &:not(:last-child) {
            margin-bottom: ${theme.sizes.SIZE_16};
          }

          &:first-child,
          &:last-child {
            font-weight: bold;
          }
        }
      }

      p {
        font-weight: 600;
        ${fontSize("16px")};
        color: var(--PRIMARY_1_1);
      }
    }

    &__contact {
      ${flex("column", "center", "center")};
      gap: ${theme.sizes.SIZE_16};
      padding: ${`${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_48}`};

      background-color: var(--GRAY-EF);

      &__header {
        font-weight: 600;
      }

      &__disclaimer {
        ${fontSize("14px")};
        margin-top: ${theme.sizes.SIZE_8};
      }
    }
  }

  > div {
    background-color: white;
  }

  @keyframes bgWhiteAnimation {
    from {
      min-width: 0;
      background-color: transparent;
    }
    to {
      min-width: 100%;
      background-color: white;
    }
  }

  @keyframes overlayFadeIn {
    from {
      filter: blur(0);
    }
    to {
      filter: blur(2px);
    }
  }

  @keyframes backgroundFadeIn {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.4);
    }
  }
`;

export { StyledLoanApproved };
