import { Button, useCallbackRef } from "@faxi/web-component-library";
import {
  FormField,
  FormRef,
  StepsContext,
  useFormRefValues,
} from "@faxi/web-form";
import parse from "html-react-parser";
import { FC, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { StorageKeys } from "common";
import {
  FormActions,
  FormStep,
  NumberInputField,
  VehicleStepHeader,
} from "components";
import { useAnalytics, useVehicle } from "providers";
import { storageService } from "services";
import {
  breakTranslationNewlines,
  convertToNumber,
  formatCurrency,
} from "utils";

type FormValue = {
  loanAmount: string;
  cashDeposit: string;
};

export const MIN_LOAN_AMOUNT = 2500;
export const MAX_LOAN_AMOUNT = 175000;

const stepValidations = (
  t: (message: string, options: Record<string, string>) => string,
) => ({
  loanAmount: (carPrice: number) => [
    (value: string | number) => {
      const loanAmount = convertToNumber(value);

      const maxLoanAmount =
        carPrice === 0 ? MAX_LOAN_AMOUNT : Math.min(MAX_LOAN_AMOUNT, carPrice);

      const loanAmountIsValid =
        loanAmount <= maxLoanAmount && loanAmount >= MIN_LOAN_AMOUNT;

      const errorMessage = t("error_enter_number_between", {
        min: formatCurrency(MIN_LOAN_AMOUNT),
        max: formatCurrency(maxLoanAmount),
      });

      return loanAmountIsValid ? "" : errorMessage;
    },
  ],
  deposit: (carPrice: number, loanAmount: number) => [
    (value: string | number) => {
      const deposit = convertToNumber(value);

      const maxDeposit = carPrice ? carPrice - MIN_LOAN_AMOUNT : loanAmount;
      const minDeposit =
        carPrice && carPrice > MAX_LOAN_AMOUNT ? carPrice - MAX_LOAN_AMOUNT : 0;

      const depositIsValid = deposit <= maxDeposit && deposit >= minDeposit;

      const errorMessage = t("error_enter_number_between", {
        min: formatCurrency(minDeposit),
        max: formatCurrency(maxDeposit),
      });

      return depositIsValid ? "" : errorMessage;
    },
  ],
});

const Step1: FC = () => {
  const [form, formRef] = useCallbackRef<FormRef>();

  const { t } = useTranslation();
  const { loanAmount } = (useFormRefValues(form, "loanAmount") || {}) as Record<
    "loanAmount",
    string
  >;

  const { nextPage } = useContext(StepsContext);
  const { data } = useVehicle();
  const analytics = useAnalytics();
  const location = useLocation();

  const handleTrackEvent = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: location.pathname,
    });
  }, [location.pathname, analytics]);

  const handleSubmit = useCallback(
    async (data: FormValue) => {
      handleTrackEvent();
      return nextPage(data);
    },
    [nextPage, handleTrackEvent],
  );

  const carPrice = data?.price ?? 0;

  const onCashDepositChange = (cashDeposit: string) => {
    const finalValue = convertToNumber(cashDeposit);
    const newLoanAmount = carPrice - finalValue;

    if (stepValidations(t).loanAmount(carPrice)[0](newLoanAmount) !== "")
      return;

    form.updateValueField("loanAmount", newLoanAmount);
  };

  const hasPlate = !!storageService.getItem(StorageKeys.VEHICLE_PLATE);

  return (
    <VehicleStepHeader
      title={parse(
        breakTranslationNewlines(
          t(`landingpage_card_subtitle_no_${hasPlate ? "vehicle" : "data"}`),
        ),
      )}
    >
      <FormStep ref={formRef} onSubmit={handleSubmit}>
        <FormField
          name="loanAmount"
          label={t("outcome_tab_loanamount")}
          validate={stepValidations(t).loanAmount(carPrice)}
          component={NumberInputField}
          disabled={!!data}
        />

        <FormField
          name="cashDeposit"
          label={t("startloan_loandeposit_text")}
          validate={stepValidations(t).deposit(
            carPrice,
            convertToNumber(loanAmount),
          )}
          component={NumberInputField}
          additionalOnChange={onCashDepositChange}
        />
        <FormActions>
          <Button type="submit">{t("next")}</Button>
        </FormActions>
      </FormStep>
    </VehicleStepHeader>
  );
};

export default Step1;
