import parse from "html-react-parser";
import React from "react";
import { useTranslation } from "react-i18next";

import { LegalDocumentsWrapper } from "components";
import { breakTranslationNewlines } from "utils";

export type PrivacyPolicyProps = {};

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  const { t } = useTranslation();

  return (
    <LegalDocumentsWrapper title={t("tfs_privacypolicy_header")}>
      {parse(breakTranslationNewlines(t("tfs_privacypolicy_text")))}
    </LegalDocumentsWrapper>
  );
};

export default PrivacyPolicy;
