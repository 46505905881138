import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";

import { API_PATHS, StorageKeys } from "common";
import { APP_URI, BASE_INQUIRY_PATH } from "common/routes";
import { useURLSearchParams } from "hooks";
import { storageService } from "services";
import { Vehicle } from "types";

import { useHistory } from "react-router";
import VehicleDataContext, { VehicleContextData } from "./VehicleData.context";

type PageSearchParams = { plate: string };

type VehicleDataProps = {
  children?: React.ReactNode;
};

const clearStorageData = () => {
  const storageKeysToRemove = [
    StorageKeys.FORM_STORAGE_KEY,
    StorageKeys.COMPLETED_STEPS_STORAGE_KEY,
    StorageKeys.LOAN_AMOUNT_RESULT_STORAGE_KEY,
    StorageKeys.SAVE_DATA_RESPONSE_KEY,
  ];

  storageKeysToRemove.forEach((storageKey) =>
    storageService.removeItem(storageKey),
  );
};

const VehicleData: React.FC<VehicleDataProps> = (props) => {
  const { children } = props;

  const isInquiryPath = window.location.pathname.startsWith(BASE_INQUIRY_PATH);
  const [shouldCalculateDefaultLoan, setShouldCalculateDefaultLoan] =
    useState<boolean>();

  const history = useHistory();

  const {
    params: { plate: urlPlate },
  } = useURLSearchParams<PageSearchParams>();

  const storedPlate = storageService.getItem(StorageKeys.VEHICLE_PLATE);
  const vehiclePlate = urlPlate || storedPlate || null;

  const { data, isLoading, error } = useQuery<Vehicle>({
    queryKey: [`${API_PATHS.VEHICLES}/${vehiclePlate}`],
    enabled: isInquiryPath && !shouldCalculateDefaultLoan,
    retry: 0,
  });

  useEffect(() => {
    const storedPlate = storageService.getItem(StorageKeys.VEHICLE_PLATE);

    if (urlPlate && urlPlate !== storedPlate) {
      clearStorageData();
      storageService.setItem(StorageKeys.VEHICLE_PLATE, urlPlate);
    }

    if (!urlPlate && window.location.pathname === APP_URI.HOME) {
      clearStorageData();
      storageService.removeItem(StorageKeys.VEHICLE_PLATE);
    }
  }, [urlPlate]);

  useEffect(() => {
    document.body.style.overflow = isInquiryPath ? "auto" : "hidden";
  }, [isInquiryPath]);

  useEffect(() => {
    if (error) {
      setShouldCalculateDefaultLoan(true);
    }
  }, [error, history]);

  return (
    <VehicleDataContext.Provider value={{ data, isLoading, error }}>
      {children}
    </VehicleDataContext.Provider>
  );
};

const useVehicle = (): VehicleContextData => useContext(VehicleDataContext);

export { VehicleData, useVehicle };
