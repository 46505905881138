import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { APP_URI } from "common";
import {
  BaseHeader,
  ButtonLink,
  DetailsInfo,
  Footer,
  FormActions,
  Loader,
} from "components";
import { useAnalytics, useVehicle } from "providers";

import {
  StyledDetailsCard,
  StyledHomePage,
  StyledPreApprovedCard,
} from "./Home.styled";
import { useLocation } from "react-router-dom";

const Home: FC = () => {
  const { isLoading, data } = useVehicle();
  const analytics = useAnalytics();

  const location = useLocation();

  const { t } = useTranslation();

  const onNextStep = useCallback(() => {
    analytics.track("Next Step", {
      stepCompleted: location.pathname,
    });
  }, [location.pathname, analytics]);

  useEffect(() => {
    if (data) {
      analytics.track("Home Page", {
        title: data.title,
        vehicleId: data.id,
        make: data.make,
        model: data.model,
        thumbnail: data.thumbnail,
        price: data.priceFormatted,
        doorCount: data.doorCount,
        colour: data.colour,
        referrer: document.referrer,
      });
    }
  }, [data, analytics]);

  if (isLoading) return <Loader isRedirecting />;

  return (
    <StyledHomePage className="ffc-home-page">
      <section className="element-max-width">
        <BaseHeader />
        <StyledPreApprovedCard variant={1}>
          <div>
            {data && (
              <>
                <h2>{t("landingpage_card_title")}</h2>
                <strong>{data?.model}?</strong>
              </>
            )}
            <p>{t("landingpage_card_subtitle")}</p>
          </div>
          {data && (
            <img src={data?.thumbnail} alt="Vechicle Details" loading="eager" />
          )}
        </StyledPreApprovedCard>
        {data && (
          <StyledDetailsCard>
            <DetailsInfo
              title={t("landingpage_price")}
              value={data?.priceFormatted}
            />
            <DetailsInfo
              title={t("landingpage_doors")}
              value={data.doorCount}
            />
            <DetailsInfo
              title={t("landingpage_modelyear")}
              value={data.model}
            />
            <DetailsInfo title={t("landingpage_colour")} value={data.colour} />
          </StyledDetailsCard>
        )}
        <div className="ffc-home-page__bottom-description">
          {t("landingpage_newdescription")}
        </div>
        <Footer>
          <FormActions>
            <ButtonLink to={APP_URI.STEP_1} onClick={onNextStep}>
              {t("landingpage_button")}
            </ButtonLink>
          </FormActions>
        </Footer>
      </section>
    </StyledHomePage>
  );
};

export default Home;
